import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


import videoBg from '../assets/work_video.mp4'
import { Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { workexamples } from '../input';
import { apiUrl } from '../config';

export default function Work() {
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const videoElement = document.getElementById('background-video');
    videoElement.addEventListener('loadeddata', () => {
      setVideoLoaded(true);
    });

    return () => {
      videoElement.removeEventListener('loadeddata', () => {
        setVideoLoaded(true);
      });
    };
  }, []);

  const Gallery = () => {
    // Function to import all images from a folder dynamically
    // function importAll(r) {
    //   return r.keys().map(r);
    // }

    // console.log(workexamples.find(x => x.id === 0))

    // Import all images from the 'images' folder
    // const images = importAll(require.context('../assets/work_img', false, /\.(png|jpe?g|svg)$/));

    const Image = ({ image, index }) => {
      const [isHovered, setIsHovered] = useState(false);
      return (
        <>
          {/* <p>{workexamples.find(x => x.id === index)?.id}</p> */}
          <Link preventScrollReset={false} to={`/work/${index}`}>
            <div
              className="overlay-gallary"
              style={{ display: isHovered ? 'none' : 'block' }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
            </div>
            <div
              className="overlay-work-gallary"
              style={{ display: isHovered ? 'block' : 'none' }}
              onMouseEnter={() => setIsHovered(isHovered)}
              onMouseLeave={() => setIsHovered(!isHovered)}
            >
              <span className="title-gallary">{workexamples.find(x => x.id === index)?.title}</span>
            </div>
            <img
              key={index}
              src={`${apiUrl}/images/work_img/${index + 1}.png`}
              alt={`${index}`}
              className="gallery-image picture"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
          </Link>
        </>
      )
    }

    return (
      <div className="gallery">
        {workexamples.map((image, index) => {
          return (
            <div className="picture-container" key={index}>
              <Image image={image} index={index} />
            </div>
          )
        })}
      </div>
    );
  };


  return (
    <div>
      <div className='contents'>
        {!videoLoaded && <div style={{ width: '100%', height: '100vh', backgroundColor: '#000' }}></div>}
        <video id="background-video" src={videoBg} autoPlay loop muted playsInline webkit-playsinline="true"/>
        <div className='content'>
          <h1>DOCUMENTARY_MUSIC</h1>
          <h1>VIDEOS_INDUSTRIAL</h1>
          <h1>FILM_COMMERCIAL_LIVE</h1>
          <h1>STREAMING</h1>
          <p style={{ fontSize: '2vw' }}>Ideal Ent. | Medienproduktion München</p>
          <Link preventScrollReset={false} to={`/contact`}>
            <Button style={{
              borderColor: "#fff", color: "#fff"
            }} variant="outlined">Lass dich idealisieren...</Button>
          </Link>
          <Box mt={8}>
            <ExpandMoreIcon />
          </Box>
        </div>
      </div>
      <Gallery />
    </div>
  );
}
