import React from 'react';
import { Link } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';

import studio from '../assets/studio_logo.png'
import logo from '../assets/logo.png'


export default function Home() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div className="fixed-wrapper">
    <Box sx={{ height: '100vh' }} display="flex" >
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection={isMobile ? 'column' : 'row'}>
      <Box m={isMobile ? 0 : 8} mb={isMobile ? 12 : 0} sx={{ width: isMobile ? '100%' : '50%' }}>
      <Link to={`/studio`} style={{ textDecoration: 'none' }}>
        <div style={{ textAlign: 'center', position: 'relative' }}>
          <img
            src={studio}
            alt="Studio"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
      </Link>
      </Box>
      <Box m={isMobile ? 0 : 8} sx={{ width: isMobile ? '100%' : '50%' }}>
      <Link to={`https://idealent.de`} style={{ textDecoration: 'none' }}>
        <div style={{ textAlign: 'center', position: 'relative' }}>
          <img
            src={logo}
            alt="Ideal Ent"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
      </Link>
      </Box>
    </Box>
    </Box>
    </div>
  );
}
