import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';



import Brightness4Icon from '@mui/icons-material/Brightness4';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import NoiseAwareIcon from '@mui/icons-material/NoiseAware';
import WeekendIcon from '@mui/icons-material/Weekend';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import VideocamIcon from '@mui/icons-material/Videocam';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import LooksIcon from '@mui/icons-material/Looks';



export const sections = [
  {
    title: "THE STUDIO",
    subtitle: "- THE HEART & SOUL",
    items: [
      { icon: <LooksIcon />, text: "HOHLKEHLE MIT PROFESSIONELLEM TOPLIGHT" },
      { icon: <WallpaperIcon />, text: "LOFTVIBES: VIELSEITIGE BACKGROUNDS IM RAUM" },
      { icon: <Brightness4Icon />, text: "MIT TAGESLICHT, ODER KOMPLETT VERDUNKELBAR" }
    ],
    boxProps: { ml: 4 }
  },
  {
    title: "PODCAST LOUNGE",
    subtitle: "- WHERE STORIES UNFOLD",
    items: [
      { icon: <NoiseAwareIcon />, text: "AKUSTISCH OPTIMIERTER RAUM" },
      { icon: <VolumeDownIcon />, text: "PROFESSIONELLE AUDIOTECHNIK VOR ORT" },
      { icon: <WeekendIcon />, text: "ENTSPANNTE AUFNAHME AUF DER COUCH, AM TISCH, ODER IM STUDIO-BEREICH" }
    ],
    boxProps: { mx: 4 }
  },
  {
    title: "ROUND TABLE",
    subtitle: "- WHERE CREATIVE MINDS MEET",
    items: [
      { icon: <WorkspacesIcon />, text: "TISCH FÜR BIS ZU 6 PERSONEN" },
      { icon: <VideocamIcon />, text: "BEAMER, BILDSCHIRM & AUDIOANLAGE" },
      { icon: <SoupKitchenIcon />, text: "TEEKÜCHE & CATERING-OPTIONEN" }
    ],
    boxProps: { mr: 4 }
  }
];

const StudioSectionOne = ({ title, subtitle, items, boxProps, isMobile }) => (
  <Box flex={1} {...(!isMobile ? boxProps : {})} mx={isMobile ? 2 : null}>
      <Typography variant="h3" display="flex" justifyContent={isMobile ? "flex-start" : "center"}>{title}</Typography>
      <br />
      <Typography variant="h4" display="flex" justifyContent={isMobile ? "flex-start" : "center"}>{subtitle}</Typography>
      <List sx={{ color: '#fff' }}>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon sx={{ color: '#fff' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body1">{item.text}</Typography>} />
          </ListItem>
        ))}
      </List>
    </Box>
);

export default StudioSectionOne;