import React from 'react';
import { Box, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';



const emailHref = `mailto:studio@idealent.de?subject=ANFRAGE%20IDEAL%20STUDIO&body=LIEBES%20IDEAL%20STUDIO%20TEAM%2C%0D%0A%0D%0AICH%20INTERESSIERE%20MICH%20F%C3%9CR%20DIE%20BUCHUNG%20EURES%20FILM-%2C%20FOTO-%2C%20PODCAST-%20UND%20EVENTSTUDIOS.%0D%0A%0D%0A---%0D%0A%0D%0ANAME%3A%0D%0AE-MAIL-ADRESSE%3A%0D%0ATELEFONNUMMER%3A%0D%0A%0D%0A---%0D%0A%0D%0AART%20DES%20PROJEKTS%3A%0D%0A%28FILMPRODUKTION%2C%20FOTOSHOOTING%2C%20PODCAST-AUFNAHME%2C%20EVENT%29%0D%0A%0D%0AGEW%C3%9CNSCHTES%20BUCHUNGSDATUM%3A%0D%0A%0D%0ADAUER%20DER%20BUCHUNG%3A%0D%0A%28STUNDEN%2C%20GANZER%20TAG%2C%20MEHRERE%20TAGE%29%0D%0A%0D%0AANZAHL%20DER%20PERSONEN%3A%0D%0A%28DIE%20AM%20PROJEKT%20TEILNEHMEN%20WERDEN%29%0D%0A%0D%0ABEN%C3%96TIGTE%20AUSSTATTUNG%3A%0D%0A%28KAMERAS%2C%20BELEUCHTUNG%2C%20TON%2C%20HINTERGRUNDKULISSEN%29%0D%0A%0D%0ASPEZIELLE%20ANFORDERUNGEN%20ODER%20W%C3%9CNSCHE%3A%0D%0A%28CATERING%2C%20TECHNISCHE%20UNTERST%C3%9CTZUNG%2C%20SPEZIELLES%20EQUIPMENT%29%0D%0A%0D%0ABUDGET%3A%0D%0A%28OPTIONAL%2C%20UM%20EIN%20PASSENDES%20ANGEBOT%20ZU%20ERSTELLEN%29%0D%0A%0D%0A---%0D%0A%0D%0AGIBT%20ES%20NOCH%20ZUS%C3%84TZLICHE%20INFORMATIONEN%20ODER%20SPEZIFISCHE%20ANFORDERUNGEN%2C%20DIE%20F%C3%9CR%20DEIN%20PROJEKT%20WICHTIG%20SIND%3F%0D%0A%0D%0AVIELEN%20DANK%20F%C3%9CR%20DEINE%20ANFRAGE.%20WIR%20WERDEN%20UNS%20SO%20SCHNELL%20WIE%20M%C3%96GLICH%20BEI%20DIR%20MELDEN%2C%20UM%20ALLE%20DETAILS%20ZU%20BESPRECHEN%20UND%20EIN%20MA%C3%9FGESCHNEIDERTES%20ANGEBOT%20ZU%20ERSTELLEN.%0D%0A%0D%0AIDEAL%20STUDIO%20BY%20IDEAL%20ENT.%0D%0AREGERPLATZ%204%0D%0A81541%20M%C3%9CNCHEN%0D%0AWWW.IDEALENT.DE`;

const phoneNumber = '+4917666881166';


export default function Footer() {
  return (
    <div className='footer-content' >
      <Box display="flex">
        <Box>
          <a style={{ textDecoration: 'none', color: 'inherit' }} href={emailHref}>
            <EmailIcon />
          </a>
        </Box>
        <Box mx={2}>
          <a
            style={{ textDecoration: 'none', color: 'inherit' }}
            href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(
              `Liebes Ideal Studio Team 📹,\n\nIch interessiere mich für die Buchung eures Film-, Foto-, Podcast- und Eventstudios.\n\n- Name: \n- E-Mail: \n- Telefon: \n- Projektart: (Filmproduktion, Fotoshooting, Podcast, Event) \n- Buchungsdatum/Dauer: \n- Anzahl Personen: \n- Budget: (Optional) \n\nGibt es noch weitere Informationen oder Anforderungen?`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon />
          </a>
        </Box>
        <Box>
          <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.instagram.com/ideal_ent/">
            <InstagramIcon />
          </a>
        </Box>
      </Box>
      <p>IDEAL ENTERTAINMENT 2024 / STUDI@IDEALENT.DE</p>
      <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://idealent.de/impressum-1">
        <Typography vartiant="body1">
          Impressum
        </Typography>
      </a>
    </div>
  );
}


